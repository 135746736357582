"use client";

import { FC, useContext } from "react";
import { Sparkles } from "lucide-react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";
type GemCounterProps = {
  gold?: number;
  showGetMore?: boolean;
  size?: "sm" | "md" | "lg";
  className?: string;
};
const Token = "/images/TokenIcon.png";

/**
 * A component that displays the user's gold amount with a token icon
 */
export const GemCounter: FC<GemCounterProps> = ({
  gold = 0,
  showGetMore = false,
  size = "md",
  className
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handlePricingClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: !state.showPricingTable
      }
    });
  };
  const sizeConfig = {
    sm: {
      container: "text-xs",
      image: 24,
      gold: "text-sm font-medium",
      sparkles: 14
    },
    md: {
      container: "text-sm",
      image: 32,
      gold: "text-base font-medium",
      sparkles: 16
    },
    lg: {
      container: "text-base",
      image: 40,
      gold: "text-lg font-semibold",
      sparkles: 18
    }
  };
  const {
    container,
    image,
    gold: goldTextClass,
    sparkles
  } = sizeConfig[size];
  return <div className={cn("flex items-center", className)} data-sentry-component="GemCounter" data-sentry-source-file="gem-counter.tsx">
            <button onClick={handlePricingClick} aria-label="Gold Counter" className="group flex items-center !gap-1 !px-1 !py-1 !rounded-lg hover:!bg-slate-800/70 transition-colors">
                <div className="relative">
                    <Image src={Token} alt="Gold" width={image} height={image} className="object-contain" priority data-sentry-element="Image" data-sentry-source-file="gem-counter.tsx" />
                    {/* Add a subtle pulse effect to the token */}
                    <div className="absolute inset-0 rounded-full bg-amber-400/20 animate-[pulse-slow]"></div>
                </div>
                <span className={cn("text-amber-300 group-hover:text-amber-200 transition-colors text-xs md:text-sm", goldTextClass)}>
                    {gold.toLocaleString()}
                </span>
            </button>

            {showGetMore && <button onClick={handlePricingClick} className={cn("ml-2 flex items-center gap-1 text-sky-400 hover:text-sky-300 px-2 py-1 rounded-lg hover:bg-slate-800/70 transition-colors", container)} aria-label="Get More Gold">
                    <Sparkles size={sparkles} />
                    <span>Get More</span>
                </button>}
        </div>;
};