"use client";

import React, { useEffect, useReducer, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import dynamic from "next/dynamic";
import Footer from "@/components/molecules/Footer";
import { Header } from "@/components/molecules/Header";
import type { AppReducerState } from "@/types";
import { AppContext } from "lib/contexts";
import { appReducer } from "lib/reducers";
import { AppReducerActionType, UserPremiumType } from "types";

// QueryClient moved to module scope
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      // 5 minutes
      cacheTime: 1000 * 60 * 30,
      // 30 minutes
      retry: 2,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      useErrorBoundary: true
    }
  }
});

// Properly type the lazy component
const EnhancedUIComponents = dynamic<{
  state: AppReducerState;
}>(() => import("./EnhancedUIComponents").then(mod => mod.default), {
  ssr: false,
  loading: () => null
});

// Split LayoutWrapper into core and enhanced versions
const LayoutWrapper = React.memo(function LayoutWrapper({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [state, dispatch] = useReducer(appReducer, {
    showPricingTable: false,
    showSubscriptionTable: false,
    gold: 0,
    cookieConsentGiven: true,
    isLoggedIn: false,
    showAuthModal: false,
    userPremiumLevel: UserPremiumType.NONE,
    isOverlayVisible: false,
    username: "",
    email: "",
    referralCode: "",
    gclid: "",
    dailyLoginBonus: null,
    user: null
  });
  const [hasHydrated, setHasHydrated] = useState(false);

  // After first render, load non-critical enhancements
  useEffect(() => {
    // Mark component as hydrated
    setHasHydrated(true);

    // Load non-critical features after main content renders
    const timer = setTimeout(() => {
      // Load user data
      import("lib/actions").then(({
        getCurrentUserInfo
      }) => {
        getCurrentUserInfo().then(({
          user
        }) => {
          if (user) {
            // Update minimal user data needed for UI
            dispatch({
              type: AppReducerActionType.SET_LOGGED_IN,
              payload: {
                ...state,
                isLoggedIn: true
              }
            });
            if (user.email) {
              dispatch({
                type: AppReducerActionType.SET_EMAIL,
                payload: {
                  ...state,
                  email: user.email
                }
              });
            }
            if (user.username) {
              dispatch({
                type: AppReducerActionType.SET_USERNAME,
                payload: {
                  ...state,
                  username: user.username
                }
              });
            }
            if (user.gold) {
              dispatch({
                type: AppReducerActionType.SET_GOLD,
                payload: {
                  ...state,
                  gold: user.gold
                }
              });
            }
            dispatch({
              type: AppReducerActionType.SET_USER_PREMIUM_LEVEL,
              payload: {
                ...state,
                userPremiumLevel: user.subscriptionType ?? UserPremiumType.NONE
              }
            });

            // Load full user data in background
            import("lib/actions").then(({
              getCurrentUserFullInfo
            }) => {
              getCurrentUserFullInfo().then(({
                user: fullUser
              }) => {
                if (fullUser) {
                  dispatch({
                    type: AppReducerActionType.SET_USER,
                    payload: {
                      ...state,
                      user: fullUser
                    }
                  });
                  if (fullUser.referralCode) {
                    dispatch({
                      type: AppReducerActionType.SET_REFERRAL_CODE,
                      payload: {
                        ...state,
                        referralCode: fullUser.referralCode
                      }
                    });
                  }
                  if (fullUser.dailyGoldBonus) {
                    dispatch({
                      type: AppReducerActionType.SET_DAILY_LOGIN_BONUS,
                      payload: {
                        ...state,
                        dailyLoginBonus: fullUser.dailyGoldBonus
                      }
                    });
                  }
                  if (fullUser.gold) {
                    dispatch({
                      type: AppReducerActionType.SET_GOLD,
                      payload: {
                        ...state,
                        gold: fullUser.gold
                      }
                    });
                  }
                }
              });
            });
          }
        });
      });
    }, 0);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <QueryClientProvider client={queryClient}>
            <AppContext.Provider value={{
      state,
      dispatch
    }}>
                <Header isLoggedIn={state.isLoggedIn} userPremiumLevel={state.userPremiumLevel} userEmail={state.email} />

                {/* Main content */}
                <main>{children}</main>

                <Footer />

                {/* Lazy-load secondary UI components after hydration */}
                {hasHydrated && <EnhancedUIComponents state={state} />}
            </AppContext.Provider>
        </QueryClientProvider>;
});
export default LayoutWrapper;