"use client";

import * as React from "react";
import { Edit, Puzzle, Sparkles, Upload, Video, Volume2Icon } from "lucide-react";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
export default function HeaderGenerateButton() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  // Only enable the dropdown on the client side to prevent hydration issues
  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  return <DropdownMenu open={isMounted ? isOpen : false} onOpenChange={isMounted ? setIsOpen : undefined} data-sentry-element="DropdownMenu" data-sentry-component="HeaderGenerateButton" data-sentry-source-file="index.tsx">
            <DropdownMenuTrigger asChild disabled={!isMounted} data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="index.tsx">
                <Button className="!bg-emerald-700 hover:!bg-emerald-800 text-white !font-medium !p-0.5 !py-0.5 !px-2 !rounded-md !shadow-md hover:!shadow-lg !transition-all !duration-200 ease-in-out flex items-center !space-x-0" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    <Sparkles className="!w-4 !h-4" data-sentry-element="Sparkles" data-sentry-source-file="index.tsx" />
                    <span className="text-xs lg:text-xs">Generate</span>
                </Button>
            </DropdownMenuTrigger>
            {isMounted && <DropdownMenuContent align="end" className="!z-[99999] !w-50 !space-y-2 !bg-background border border-slate-800">
                    <DropdownMenuItem>
                        <Link title="Generate Image" href="/generate" prefetch={false}>
                            <div className="flex space-4">
                                <Sparkles className="mr-2 h-4 w-4" />
                                <span>Generate Image</span>
                            </div>
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Link title="Generate Video" href="/generate?contentType=video" prefetch={false}>
                            <div className="flex">
                                <Video className="mr-2 h-4 w-4" />
                                <span>Generate Video</span>
                            </div>
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Link title="Generate Audio" href="/generate?contentType=audio" prefetch={false}>
                            <div className="flex">
                                <Volume2Icon className="mr-2 h-4 w-4" />
                                <span>Generate Audio</span>
                            </div>
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Link title="Upload Image" href="/generate?formType=upload" prefetch={false}>
                            <div className="flex">
                                <Upload className="mr-2 h-4 w-4" />
                                <span>Upload Image</span>
                            </div>
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Link title="Edit Image" href="/edit" prefetch={false}>
                            <div className="flex">
                                <Edit className="mr-2 h-4 w-4" />
                                <span>Edit Image</span>
                            </div>
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Link title="Create Lora" href="/lora/create" prefetch={false}>
                            <div className="flex">
                                <Puzzle className="mr-2 h-4 w-4" />
                                <span>Create LORA</span>
                            </div>
                        </Link>
                    </DropdownMenuItem>
                </DropdownMenuContent>}
        </DropdownMenu>;
}