import { FC, useCallback, useContext } from "react";
import { Auth } from "@aws-amplify/auth";
import { setUser } from "@sentry/react";
import { Image as ImageIcon, LogIn, LogOut, User } from "lucide-react";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";

// Define props for AuthButton
interface AuthButtonProps {
  onClick?: () => void; // Optional onClick handler
}
export const AuthButton: FC<AuthButtonProps> = ({
  onClick
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handleRegister = useCallback(() => {
    // First, dispatch the action to show the modal
    dispatch({
      type: AppReducerActionType.SHOW_AUTH_MODAL,
      payload: {
        ...state,
        showAuthModal: true
      }
    });
    // Then, call the passed-in onClick handler, if it exists
    onClick?.();
  }, [dispatch, state, onClick]);
  return <Button onClick={handleRegister} aria-label="Sign Up | Log in" className="bg-slate-800 hover:bg-slate-700 text-white border border-slate-700 w-full" // Added w-full for consistency in sidebar
  data-sentry-element="Button" data-sentry-component="AuthButton" data-sentry-source-file="index.tsx">
            <LogIn className="mr-2 h-4 w-4" data-sentry-element="LogIn" data-sentry-source-file="index.tsx" />
            <span>Sign Up | Log in</span>
        </Button>;
};
export const SignOutButton: FC = () => {
  const handleSignOut = useCallback(async () => {
    setTimeout(async () => {
      await Auth.signOut();
      setUser(null);
      window.location.replace(window.location.origin);
    }, 500);
  }, []);
  return <Button onClick={handleSignOut} variant="outline" aria-label="Sign Out" className="w-full justify-start text-slate-200 bg-slate-800 border-slate-700 hover:bg-slate-700 hover:text-white" data-sentry-element="Button" data-sentry-component="SignOutButton" data-sentry-source-file="index.tsx">
            <LogOut className="mr-2 h-4 w-4" data-sentry-element="LogOut" data-sentry-source-file="index.tsx" />
            <span>Sign Out</span>
        </Button>;
};
export const ProfileButton: FC = () => {
  return <Button asChild variant="outline" aria-label="Profile" className="w-full justify-start text-slate-200 bg-slate-800 border-slate-700 hover:bg-slate-700 hover:text-white" data-sentry-element="Button" data-sentry-component="ProfileButton" data-sentry-source-file="index.tsx">
            <Link href="/profile" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <User className="mr-2 h-4 w-4" data-sentry-element="User" data-sentry-source-file="index.tsx" />
                <span>Profile</span>
            </Link>
        </Button>;
};
export const MyGalleryButton: FC = () => {
  return <Button asChild variant="outline" aria-label="My Gallery" className="w-full justify-start text-slate-200 bg-slate-800 border-slate-700 hover:bg-slate-700 hover:text-white" data-sentry-element="Button" data-sentry-component="MyGalleryButton" data-sentry-source-file="index.tsx">
            <Link href="/my-gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <ImageIcon className="mr-2 h-4 w-4" data-sentry-element="ImageIcon" data-sentry-source-file="index.tsx" />
                <span>My Gallery</span>
            </Link>
        </Button>;
};