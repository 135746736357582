import { Auth } from "@aws-amplify/auth";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { Nunito } from "next/font/google";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import LayoutWrapper from "components/organisms/LayoutWrapper";
import "tailwind.css";

// Font optimization
const nunito = Nunito({
  weight: ["400", "600", "700"],
  display: "swap",
  subsets: ["latin"],
  preload: true,
  fallback: ["system-ui", "arial", "sans-serif"],
  variable: "--font-nunito",
  adjustFontFallback: true
});
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

// Configure Auth outside of the component to avoid re-initialization
Auth.configure({
  region: "eu-west-2",
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEB_CLIENT,
  oauth: {
    domain: process.env.NEXT_PUBLIC_COGNITO_AUTH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.NEXT_PUBLIC_COGNITO_REDIRECT,
    redirectSignOut: process.env.NEXT_PUBLIC_COGNITO_REDIRECT,
    responseType: "code"
  }
});
const GoogleTagManager = dynamic(() => import("../components/utils/GoogleTagManager"), {
  ssr: false,
  loading: () => null
});
function MyApp({
  Component,
  pageProps
}: AppProps) {
  return <>
            {/* Default SEO configuration */}
            <DefaultSeo title="CharGen: Fantasy AI Image Generator - Create Art, Images & Videos" description="Create stunning AI-generated art for your D&D characters in seconds with CharGen. Start crafting unique RPG character images for free today!" canonical="https://char-gen.com" openGraph={{
      type: "website",
      locale: "en_GB",
      url: "https://char-gen.com",
      siteName: "CharGen",
      title: "CharGen: Fantasy AI Image Generator - Create Art, Images & Videos",
      description: "Fantasy AI Image Generator made for Fantasy RPGs. Generate AI Art for your D&D characters in seconds with CharGen.",
      images: [{
        url: "https://charsheet-gen.s3.eu-west-2.amazonaws.com/fantasy-ai-character-generator.png",
        width: 1200,
        height: 630,
        alt: "CharGen Fantasy AI Image Generator",
        type: "image/png"
      }]
    }} twitter={{
      handle: "@CharGenAI",
      site: "@CharGenAI",
      cardType: "summary_large_image"
    }} additionalMetaTags={[{
      name: "viewport",
      content: "width=device-width, initial-scale=1, viewport-fit=cover"
    }, {
      name: "author",
      content: "CharGen Team"
    }]} data-sentry-element="DefaultSeo" data-sentry-source-file="_app.tsx" />

            <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                {/* Preload critical resources */}
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://api.char-gen.com" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://charsheet-gen.s3.eu-west-2.amazonaws.com" crossOrigin="anonymous" />
                {/* Defer non-critical resource hints */}
                <link rel="dns-prefetch" href="https://www.googletagmanager.com/" />
                <GoogleTagManager data-sentry-element="GoogleTagManager" data-sentry-source-file="_app.tsx" />
            </Head>
            <div id="root" className={nunito.className}>
                <LayoutWrapper data-sentry-element="LayoutWrapper" data-sentry-source-file="_app.tsx">
                    <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </LayoutWrapper>
            </div>
        </>;
}
export default MyApp;