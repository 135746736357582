globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"chargen-fe@ffa6faf66531f53dc6eb8f5d2a605523e54f990b"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/react";

const env = process.env.NODE_ENV;

const release = process.env.NEXT_PUBLIC_SENTRY_RELEASE;

Sentry.init({
    environment: env,
    dsn: "https://aec796a5cae306752ef8517cdb44987d@sentry.vorontsov.co.uk/1",
    release: release || "",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
    ],
    enabled: true,
    tracePropagationTargets: ["localhost", /api\.char-gen\.com/],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
});
