import { FC } from "react";
import { DiscordLogoIcon } from "@radix-ui/react-icons";
import { Facebook, Wand2 } from "lucide-react";
import { Instagram, Twitter as TwitterIcon } from "lucide-react";
import Link from "next/link";

// Footer component
const Footer: FC = () => {
  return <footer className="border-t border-slate-800 bg-black/50" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
            <div className="container mx-auto px-4 py-12">
                <div className="grid grid-cols-3 md:grid-cols-6 gap-8 mb-8">
                    <div>
                        <h4 className="font-bold text-white mb-4">Product</h4>
                        <nav className="flex flex-col space-y-2">
                            <Link href="/pricing" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Pricing
                            </Link>
                            <Link href="/gallery" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Gallery
                            </Link>
                            <Link title="Generate Fantasy Loot" href="/loot-generator" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Loot Generator
                            </Link>
                            <Link title="Generate Fantasy Shops" href="/shop-generator" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Shop Generator
                            </Link>
                            <Link title="Generate Fantasy Taverns" href="/tavern-generator" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Tavern Generator
                            </Link>
                            <Link title="Generate Fantasy NPCs" href="/npc-generator" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                NPC Generator
                            </Link>
                        </nav>
                    </div>
                    <div>
                        <h4 className="font-bold text-white mb-4">Company</h4>
                        <nav className="flex flex-col space-y-2">
                            <Link href="/about" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                About
                            </Link>
                            <Link href="/blogs" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Blogs
                            </Link>
                        </nav>
                    </div>

                    <div>
                        <h4 className="font-bold text-white mb-4">Legal</h4>
                        <nav className="flex flex-col space-y-2">
                            <Link href="/privacy" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Privacy Policy
                            </Link>
                            <Link href="/terms-and-conditions" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Terms & Conditions
                            </Link>
                        </nav>
                    </div>

                    <div>
                        <h4 className="font-bold text-white mb-4">Resources</h4>
                        <nav className="flex flex-col space-y-2">
                            <Link href="/leaderboards" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Leaderboards
                            </Link>
                            <Link href="/competitions" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Competitions
                            </Link>
                            <Link href="/loras" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Models (Loras)
                            </Link>
                        </nav>
                    </div>
                    <div>
                        <h4 className="font-bold text-white mb-4">Generate</h4>
                        <nav className="flex flex-col space-y-2">
                            <Link title="Generate DND Characters" href="/generate?categories=characters" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Characters
                            </Link>
                            <Link title="Generate DND NPCs" href="/generate?categories=npcs" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                NPCs
                            </Link>
                            <Link title="Generate DND Monsters" href="/generate?categories=monsters" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Monsters
                            </Link>
                            <Link title="Generate DND Landscapes" href="/generate?categories=landscapes" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Landscapes
                            </Link>
                            <Link title="Generate DND Items" href="/generate?categories=items" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Items
                            </Link>
                            <Link title="Generate DND Battlemaps" href="/generate?categories=battlemaps" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Battlemaps
                            </Link>
                        </nav>
                    </div>
                    <div>
                        <h4 className="font-bold text-white mb-4">
                            Generators
                        </h4>
                        <nav className="flex flex-col space-y-2">
                            <Link title="Generate AI Video Cutscenes" href="/generation/video-cutscenes" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                Video Cutscenes
                            </Link>
                            <Link title="Generate DND AI Art" href="/generation/dnd-ai-art" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                D&D AI Art
                            </Link>
                            <Link title="Generate DND Battlemaps" href="/generation/dnd-battlemap" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                D&D Battlemaps
                            </Link>
                            <Link title="Generate DND Characters" href="/generation/dnd-character" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                D&D Characters
                            </Link>
                            <Link title="Generate DND NPCs" href="/generation/dnd-npc" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                D&D NPCs
                            </Link>
                            <Link title="Generate DND Monsters" href="/generation/dnd-monster" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                D&D Monsters
                            </Link>
                        </nav>
                    </div>
                </div>

                <div className="border-t border-slate-800 pt-8">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                        <div className="flex items-center gap-2">
                            <Wand2 className="h-6 w-6 text-blue-500" data-sentry-element="Wand2" data-sentry-source-file="index.tsx" />
                            <span className="font-bold text-white">
                                CharGen
                            </span>
                        </div>
                        <p className="text-sm text-slate-400">
                            © {new Date().getFullYear()} CharGen. All rights
                            reserved.
                        </p>
                        <p>Made with 💖 by the CharGen team</p>
                        <div className="flex gap-4">
                            <Link href="https://twitter.com/char_genAI" target="_blank" rel="noopener noreferrer" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <span className="sr-only">Twitter</span>
                                <TwitterIcon className="h-5 w-5" data-sentry-element="TwitterIcon" data-sentry-source-file="index.tsx" />
                            </Link>
                            <Link href="https://discord.gg/4bCmfqRwqu" target="_blank" rel="noopener noreferrer" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <span className="sr-only">Discord</span>
                                <DiscordLogoIcon className="h-5 w-5" data-sentry-element="DiscordLogoIcon" data-sentry-source-file="index.tsx" />
                            </Link>
                            <Link href="https://www.facebook.com/chargenofficial" className="text-slate-400 hover:text-blue-400 transition-colors" target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <span className="sr-only">Facebook</span>
                                <Facebook className="h-5 w-5" data-sentry-element="Facebook" data-sentry-source-file="index.tsx" />
                            </Link>
                            <Link href="https://www.instagram.com/chargen_ai/" target="_blank" rel="noopener noreferrer" className="text-slate-400 hover:text-blue-400 transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <span className="sr-only">Instagram</span>
                                <Instagram className="h-5 w-5" data-sentry-element="Instagram" data-sentry-source-file="index.tsx" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>;
};
export default Footer;