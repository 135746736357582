"use client";

import { FC, useContext } from "react";
import { Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { GemCounter } from "@/components/ui/gem-counter";
import { cn } from "@/lib/utils";
import { AppContext } from "lib/contexts";
import { AppReducerActionType, UserPremiumType } from "types";
type GemCounterHeaderProps = {
  userPremiumLevel?: UserPremiumType;
  userEmail?: string;
  className?: string;
  orientation?: "horizontal" | "vertical";
};

/**
 * A component for displaying gem counter with subscription options
 */
export const GemCounterHeader: FC<GemCounterHeaderProps> = ({
  className,
  orientation = "horizontal"
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  return <div className={cn("flex gap-3 items-center", orientation === "vertical" && "flex-col w-full", className)} data-sentry-component="GemCounterHeader" data-sentry-source-file="gem-counter-header.tsx">
            <GemCounter gold={state.gold} showGetMore={orientation === "horizontal"} size={orientation === "horizontal" ? "md" : "lg"} data-sentry-element="GemCounter" data-sentry-source-file="gem-counter-header.tsx" />

            {orientation === "vertical" && <Button onClick={() => {
      dispatch({
        type: AppReducerActionType.SHOW_PRICING_TABLE,
        payload: {
          ...state,
          showPricingTable: !state.showPricingTable
        }
      });
    }} variant="secondary" className="!w-full !bg-slate-800 hover:!bg-slate-700 !text-slate-200" size="sm">
                    <Sparkles className="mr-2 h-4 w-4 !text-amber-400" />
                    Get More Gold
                </Button>}
        </div>;
};

/**
 * A button component for subscribing
 */
export const SubscribeButton: FC<{
  userPremiumLevel?: UserPremiumType;
  className?: string;
  size?: "default" | "sm" | "lg";
  fullWidth?: boolean;
}> = ({
  userPremiumLevel,
  className,
  size = "default",
  fullWidth = false
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const isPremium = userPremiumLevel !== UserPremiumType.NONE;
  if (isPremium) return null;
  return <Button onClick={() => {
    dispatch({
      type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
      payload: {
        ...state,
        showSubscriptionTable: !state.showSubscriptionTable
      }
    });
  }} size={size} aria-label="Subscribe" className={cn("bg-gradient-to-r from-emerald-600 to-emerald-500 hover:from-emerald-700 hover:to-emerald-600 text-white", fullWidth && "w-full", className)} data-sentry-element="Button" data-sentry-component="SubscribeButton" data-sentry-source-file="gem-counter-header.tsx">
            <Sparkles className="mr-2 h-4 w-4" data-sentry-element="Sparkles" data-sentry-source-file="gem-counter-header.tsx" />
            Subscribe
        </Button>;
};