export type IPromptOptions = {
    shortName: string;
    fullName: string;
    premium: boolean;
};

export interface IFieldOptions {
    npcFieldsMap: {
        [key: string]: string[];
    };
}

export interface FilterFieldDTO {
    label: string;
    values: string[];
    isFreeText: boolean;
    isCheckbox: boolean;
}

export interface ImageSearchQueryDTO {
    query?: string;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, string>;
    page?: number;
    pageSize?: number;
    userGallery?: boolean;
    createdAfter?: string | null;
    createdBefore?: string | null;
}

export interface ImageDTO {
    id: string;
    image: string;
    public: boolean;
    voteCount: number;
    createdAt: Date;
    votedByUser: boolean;
    imageMetadata: ImageMetadata;
    parentImage?: ImageDTO;
    childImages?: ImageDTO[];
    folderIds: string[];
    webpImage?: string;
    videoUrl?: string;
    loraList?: LoraDTO[];
    viewCount?: number;
}

export interface ImageMetadata {
    npcFieldsMap: {
        [key: string]: string;
    };
    theme: string;
    premium: boolean;
    username: string;
    description: string;
    modelData?: IFreeGenModelDTO;
    requestType: RequestType;
    userDTO: IUserDTO;
    name?: string;
    tags?: string[];
}

export interface Option {
    value: string;
    label: string;
    isDisabled?: boolean;
    __isNew__?: boolean;
}

export interface GroupedOption {
    label: string;
    options: Option[];
}

export enum AppReducerActionType {
    SHOW_PRICING_TABLE,
    SHOW_SUBSCRIPTION_TABLE,
    DEC_GOLD,
    SET_GOLD,
    SET_COOKIE_CONSENT,
    SET_LOGGED_IN,
    SHOW_AUTH_MODAL,
    SET_USER_PREMIUM_LEVEL,
    TOGGLE_OVERLAY_VISIBILITY,
    SET_USERNAME,
    SET_EMAIL,
    SET_REFERRAL_CODE,
    SET_GCLID,
    SET_DAILY_LOGIN_BONUS,
    SET_USER,
}

export enum UserPremiumType {
    NONE = "NONE",
    BASIC = "BASIC",
    PLUS = "PLUS",
    PRO = "PRO",
    ELITE = "ELITE",
    ULTIMATE = "ULTIMATE",
}

export enum EditType {
    IMAGE = "IMAGE",
    ADETAILER = "ADETAILER",
    BGREMOVAL = "BGREMOVAL",
    FACESWAP = "FACESWAP",
    INPAINTING = "INPAINTING",
    UPSCALER = "UPSCALER",
    SUBJECTTRANSFORMATION = "SUBJECTTRANSFORMATION",
    OUTPAINTING = "OUTPAINTING",
    QUICKEDIT = "QUICKEDIT",
}

export enum RequestType {
    CHARACTER = "CHARACTER",
    NPC = "NPC",
    MONSTER = "MONSTER",
    LANDSCAPE = "LANDSCAPE",
    ITEM = "ITEM",
    BATTLEMAP = "BATTLEMAP",
    EDIT = "EDIT",
    VIDEO = "VIDEO",
    AUDIO = "AUDIO",
}

export enum PresetType {
    CHARACTER = "CHARACTER",
    MONSTER = "MONSTER",
    NPC = "NPC",
    ITEM = "ITEM",
    LANDSCAPE = "LANDSCAPE",
    BATTLEMAP = "BATTLEMAP",
}

export interface AppReducerAction {
    type: AppReducerActionType;
    payload: AppReducerState;
}

export interface AppReducerState {
    showPricingTable: boolean;
    showSubscriptionTable: boolean;
    gold: number;
    cookieConsentGiven: boolean;
    isLoggedIn: boolean;
    showAuthModal: boolean;
    userPremiumLevel: UserPremiumType;
    isOverlayVisible?: boolean;
    username: string;
    email: string;
    referralCode: string;
    gclid: string;
    dailyLoginBonus: DailyGoldBonusDTO | null;
    user: IUserDTO | null;
}

export type IResponse = {
    data?: any;
    error?: any;
};

export type IError = {
    error: string;
    message: string;
    __sentry_captured__?: boolean;
};

export type IGenerateImageResponse = {
    requestId?: string;
    error?: string | IError;
};

export type IPollGenerateImage = {
    image?: ImageDTO[];
    error?: string | IError;
};

export type ISessionsResponse = {
    sessionNames?: string[];
    error?: string | IError;
};

export type ISessionResponse = {
    session?: SessionDTO;
    error?: string | IError;
};

export type ISessionCostResponse = {
    sessionCost?: SessionResponseDTO;
    error?: string | IError;
};

export type IRecentImageResponse = {
    image?: ImageDTO[];
    error?: string | IError;
};

export type IImagesSearchResponse = {
    images?: ImageDTO[];
    totalCount?: number;
    error?: string | IError;
};

export type IImagesResponse = {
    image?: ImageDTO[];
    error?: string | IError;
};

export type IImageResponse = {
    image?: ImageDTO;
    error?: string | IError;
};

export type ICompetitionResponse = {
    competition?: CompetitionDTO;
    error?: string | IError;
};

export type ICompetitionsResponse = {
    competition?: CompetitionDTO[];
    error?: string | IError;
};

export type IEntryResponse = {
    entry?: EntryDTO;
    error?: string | IError;
};

export type IUserResponse = {
    user?: IUserDTO;
    error?: string | IError;
};

export type IUserChangeLogViewResponse = {
    unseenChangelogs?: UserChangelogViewDTO[];
    error?: string | IError;
};

export type IUserDTO = {
    userId: string;
    username: string;
    email?: string;
    bio?: string;
    marketingConsent?: boolean;
    referralCode?: string;
    subscriptionType?: UserPremiumType;
    dailyGoldBonus?: DailyGoldBonusDTO;
    hasUnseenChangelogs?: boolean;
    gold?: number;
    requestsInLastDay?: number;
    stats?: IUserStats;
    achievements?: IAchievement[];
    followers?: IUserSummary[];
    following?: IUserSummary[];
    galleryImages?: string[];
    avatarUrl?: string;
    streak: number;
    loraTokens?: number;
    defaultPageSize?: number;
};

export type IUserStats = Record<string, number>;

export type IAchievement = {
    id: string;
    name: string;
    description?: string;
    dateAchieved?: string; // ISO date string
    iconUrl?: string;
};

export type IUserSummary = {
    userId: string;
    username: string;
    avatarUrl?: string;
    streak: number;
};

export type ICommentResponse = {
    comment?: ICommentDTO;
    error?: string | IError;
};

export type ICommentsResponse = {
    comments?: ICommentDTO[];
    error?: string | IError;
};

export type ICreateCommentRequest = {
    content: string;
    attachmentUrl?: string;
    parentCommentId?: string;
    imageId: string;
};

export type IFolderResponse = {
    folder?: FolderDTO;
    error?: string | IError;
};

export type IFoldersResponse = {
    folders?: FolderDTO[];
    error?: string | IError;
};

export interface ICommentDTO {
    id: string;
    content: string;
    attachmentUrl?: string;
    parentCommentId?: string;
    imageId: string;
    authorEmail: string;
    createdAt: string;
    updatedAt: string;
    likes: number;
    likedByCurrentUser: boolean;
    user: IUserDTO;
    replies: ICommentDTO[];
    edited: boolean;
    hasMoreReplies: boolean;
}

export type IVisibilityRequest = {
    isVisible?: boolean;
};

export type IVoteRequest = {
    isUpvote?: boolean;
};

export type IUpdateUserRequest = {
    username?: string;
    marketingConsent?: boolean | null;
    defaultPageSize?: number;
};

export type IDeleteImageRequest = {
    imageIds: string[];
};

export type IArtStylesResponse = {
    artStyles: IArtStyle[];
};

export interface IModelConfig {
    modelName: string;
    defaultSampler: string;
    defaultSteps: number;
    defaultCfgScale: number;
    thumbnailUrl: string;
    subscriberOnly: boolean;
    imageToImage: boolean;
    hasNegativePrompt: boolean;
    description: string;
    hasControlNet: boolean;
    imageToImageOnly: boolean;
}

export interface IArtStyle {
    name: string;
    url: string;
}

export interface IModelDataResponse {
    modelConfigs: IModelConfig[];
    samplers: string[];
    models: string[];
}

export interface IFreeGenModelDTO {
    model: string;
    steps?: string;
    cfg?: string;
    sampler?: string;
}

export interface ILeaderboardEntryDTO {
    username: string;
    totalVoteCount: number;
    avatarUrl?: string;
    subscriptionType?: UserPremiumType;
}

export interface ILeaderboardDTO {
    name: string;
    entries: ILeaderboardEntryDTO[];
}

export interface IPresetDTO {
    id: string;
    name: string;
    prompt: string;
    artStyle: string;
    theme: string;
    requestFields: { [key: string]: string };
    fileUrl?: string;
    file?: File;
    presetType: PresetType;
    additionalField?: string;
    freeGenModelDTO?: IFreeGenModelDTO;
    premium: boolean;
    isCustomConfig: boolean;
    simpleForm: boolean;
}

export interface CriteriaDTO {
    id: string;
    competitionId: string;
    criteriaFields: { [key: string]: string };
}

export interface PrizeDTO {
    id: string;
    competitionId: string;
    rank: number;
    gold: number;
    subscriptionType: string;
    badge: string;
    entryId: string;
    image?: ImageDTO;
}

export interface EntryDTO {
    id: string;
    competitionId: string;
    userId?: string;
    imageId: string;
    rank?: number;
    prizeId?: string;
}

export interface CompetitionDTO {
    id: string;
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    competitionEnded: boolean;
    criteria: CriteriaDTO[];
    prizes: PrizeDTO[];
    topEntries: EntryDTO[];
}

export interface UserChangelogViewDTO {
    changelogId: string;
    changelogTitle: string;
    changelogDescription: string;
    changelogImageUrl: string;
    changelogTimestamp: string;
}

export interface DailyGoldBonusDTO {
    bonusAwarded: boolean;
    dailyAwarded: boolean;
    dailyGoldAmount: number;
    bonusAmount: number;
    currentStreak: number;
    maxStreak: number;
}

export type SiteConfig = {
    avatar?: string;
    siteUrl: string;
    siteName: string;
    siteDescription: string;
    siteThumbnail: string;
    nav: Array<{ label: string; href: string }>;
    social?: {
        github?: string;
        twitter?: string;
        linkedin?: string;
        instagram?: string;
    };
};

export type MDXFrontMatter = {
    slug: string;
    title: string;
    description?: string;
    date: string;
    updated?: string;
    tags?: Array<string>;
    image?: string; // URL for blog post image
    content?: string; // Raw content for reading time calculation
};

export interface INotification {
    id: string;
    type: string;
    relatedEntityId?: string;
    relatedUsername?: string;
    message: string;
    isRead: boolean;
    createdAt: string;
}

export interface INotificationsResponse {
    notifications?: INotification[];
    error?: string | IError;
}

export interface INotificationResponse {
    notification?: INotification;
    error?: string | IError;
}

export interface GameEntityDTO {
    id: string;
    name: string;
    description: string;
    crLevel?: number;
    level?: number;
    hitPoints: number;
    armorClass: number;
    speed: string;
    size: string;
    environment: string;
    alignment: string;
    languages: string[];
    imageId: string;
    userId: string;
    entityType: string;
    [key: string]: any;
}

export interface ICreateMonsterStatsRequest {
    imageId: string;
    name: string;
    crLevel: number;
    entityType: string;
}

export interface IMonsterStatsResponse {
    taskId?: string;
    error?: any;
}

export enum GameType {
    DND5e = "DnD5e",
    PATHFINDER = "Pathfinder",
}

export interface DnD5eMonsterDTO extends GameEntityDTO {
    monsterType: string;
    skills: SkillDTO[];
    spells: SpellDTO[];
    savingThrows: SavingThrowDTO[];
    legendaryActions: LegendaryActionDTO[];
    actions: ActionDTO[];
    reactions: ActionDTO[];
    attributes: AttributeDTO[];
    equipment: EquipmentDTO[];
}

export interface DnD5eNPCDTO extends GameEntityDTO {
    npcClass: string;
    race: string;
    background: string;
    occupation: string;
    factionAffiliations: string;
    personalityTraits: string[];
    goal: string;
    secret: string;
    flaws: string[];
    fears: string[];
    wantsNeeds: string[];
    services: string[];
    rewards: string[];
    dialoguePatterns: string[];
    bodyLanguage: string;
    voice: string;
    behaviorPatterns: string[];
    majorSecret: string;
    minorSecrets: string;
    interactionStrategy: string;
    combatStrategy: string;
    attributes: AttributeDTO[];
    skills: SkillDTO[];
    actions: ActionDTO[];
    equipment: EquipmentDTO[];
}

export interface ICreateNPCRequest {
    entityType: string;
    name: string;
    level: number; // This is the NPC's level
    imageId: string;
}

export interface INPCStatsResponse {
    taskId?: string; // Optional task ID if the process is asynchronous
    error?: any; // Optional error field for capturing any issues
}

export interface AttributeDTO {
    name: string;
    value: string;
    modifier: string;
}

export interface SkillDTO {
    name: string;
    bonus: string;
}

export interface SpellDTO {
    name: string;
    level: number;
    school: string;
    description: string;
}

export interface SavingThrowDTO {
    attribute: AttributeDTO;
    bonus: string;
}

export interface LegendaryActionDTO {
    name: string;
    description: string;
    cost: number;
}

export interface EquipmentDTO {
    name: string;
    type: string;
    description: string;
    value: number;
}

export interface ActionDTO {
    name: string;
    description: string;
}

export interface SessionDTO {
    id: string;
    name: string;
    username: string;
    sessionCost: SessionResponseDTO;
}

export interface SessionResponseDTO {
    totalCost: number;
    chargedGold: number;
}

export interface FolderDTO {
    id: string;
    name: string;
    description?: string;
    parentFolderId?: string;
    subFolders?: FolderDTO[];
    note: NoteDTO;
}

export interface NoteDTO {
    id: string;
    content: string;
    folderId: string;
    createdAt: string;
    updatedAt: string;
}

export interface TutorialCompletionDTO {
    tutorialId: string;
    completed: boolean;
    completionDate: string; // ISO date string
}

export type ITutorialCompletionsResponse = {
    tutorials?: TutorialCompletionDTO[];
    error?: string | IError;
};

export type ITutorialCompletionResponse = {
    success?: boolean;
    error?: string | IError;
};

export interface TaskDTO {
    id: string;
    title: string;
    description: string;
    goal: number;
    reward: number;
    iconName: string;
    iconColor: string;
    iconStyle: string;
    currentProgress: number;
    completed: boolean;
    taskType: "REGULAR" | "DAILY";
    badgeName: string;
    badgeColor: string;
    badgeIcon: string;
    category: string;
}

// Task response type for fetching regular tasks and daily tasks
export type ITasksResponse = {
    tasks?: TaskDTO[];
    dailyTasks?: TaskDTO[];
    error?: string | IError;
};

export enum LoraStatus {
    SUBMITTED = "SUBMITTED",
    READY = "READY",
    ERROR = "ERROR",
}

export type ILoraResponse = {
    lora?: LoraDTO;
    error?: string | IError;
};

export interface LoraDTO {
    id: string;
    loraName: string;
    triggerWord: string;
    userDTO: IUserDTO;
    likes: number;
    createdAt?: string;
    updatedAt?: string;
    status?: LoraStatus;
    shortDescription?: string;
    longDescription?: string;
    coverImage?: string;
    isPublic?: boolean;
    tags?: string[];
    air?: string;
    // images?: string[];
    isLiked?: boolean;
    weight?: number;
    style?: string;
    model?: string;
}

export type LoraMode = "character" | "product" | "style" | "general";

export interface IPresignedUrlsResponse {
    urls?: Record<string, string>;
    error?: string;
}

export interface IUpdateImageResponse {
    image?: ImageDTO;
    error?: string;
}

export interface ImageHistoryEntry {
    id: string;
    beforeMedia: MediaInput;
    afterMedia: MediaInput;
    timestamp: number;
    toolName: string | null;
}

export type MediaInput = {
    type: "base64" | "url" | "file" | "dto";
    content: string | File | ImageDTO;
};

export enum VoiceGender {
    MALE = "Male",
    FEMALE = "Female",
    UNKNOWN = "Unknown",
}

export interface VoiceItem {
    fullName: string;
    name: string;
    languageInfo: string; // e.g. "English (US)/American"
    gender: VoiceGender;
}

export interface Shopkeeper {
    name: string;
    level: number;
    race: string;
    class: string; // Maps to shopkeeperClass in Java
}

export interface Attributes {
    attackBonus?: number;
    damageDice?: string;
    defenseRating?: number;
    healingAmount?: number;
    specialEffects?: string;
    otherDetails?: string;
}

export interface InventoryItem {
    itemName: string;
    price: string;
    description: string;
    itemType: "weapon" | "potion" | "armor" | "misc";
    attributes: Attributes;
}

export interface ShopGenerationResponseDTO {
    shopName: string;
    shopkeeper: {
        name: string;
        race: string;
        class: string;
        level: number;
    };
    inventory: InventoryItem[];
    quirks?: string;
}

export interface IShopGenerationResponse {
    response?: ShopGenerationResponse;
    error?: string;
}

export interface ShopGenerationResponse {
    id: string; // UUID in string format
    inputData: string;
    outputData: ShopGenerationResponseDTO;
    createdAt: string; // ISO date string
    updatedAt: string; // ISO date string
    user: IUserDTO;
    additionalImages?: ImageReferenceDTO[];
}

export interface IShopGenerationRequest {
    partyLevel: number;
    shopType: string;
    theme: string;
    extraDetails: string;
    gameMode: string;
}

export interface EditShopRequest {
    shopName: string;
    shopkeeper: {
        name: string;
        level: number;
        race: string;
        class: string;
    };
    inventory: {
        itemName: string;
        price: string;
        description: string;
        itemType: "weapon" | "potion" | "armor" | "misc";
        attributes?: {
            attackBonus?: number;
            damageDice?: string;
            defenseRating?: number;
            healingAmount?: number;
            specialEffects?: string;
            otherDetails?: string;
        };
    }[];
    quirks: string;
}

export interface AddInventoryItemRequest {
    itemName: string;
    price: string;
    description: string;
    itemType: "weapon" | "potion" | "armor" | "misc";
    attributes?: {
        attackBonus?: number;
        damageDice?: string;
        defenseRating?: number;
        healingAmount?: number;
        specialEffects?: string;
        otherDetails?: string;
    };
}

// Tavern Types
export interface Tavernkeeper {
    name: string;
    level: number;
    race: string;
    class: string;
}

export interface Amenity {
    name: string;
    description: string;
    type: "food" | "drink" | "entertainment" | "service";
}

export interface Patron {
    name: string;
    role: string;
    personality: string;
}

export interface TavernGenerationResponseDTO {
    tavernkeeper: Tavernkeeper;
    tavernName: string;
    amenities: Amenity[];
    quirks: string;
    intrigue: string;
    plotHooks: string[];
    patrons: Patron[];
    history: string;
    lore: string;
    menu?: MenuItem[];
}

export interface MenuItem {
    name: string;
    description: string;
    type: string;
    price: string;
}

export interface ITavernGenerationResponse {
    response?: TavernGenerationResponse;
    error?: string;
}

export interface TavernGenerationResponse {
    id: string;
    inputData: string;
    outputData: TavernGenerationResponseDTO;
    createdAt: string;
    updatedAt: string;
    user: IUserDTO;
    additionalImages?: ImageReferenceDTO[];
}

export interface ITavernGenerationRequest {
    partyLevel: number;
    tavernType: string;
    theme: string;
    extraDetails: string;
    gameMode: string;
}

export interface EditTavernRequest {
    tavernName: string;
    tavernkeeper: Tavernkeeper;
    amenities: Amenity[];
    quirks: string;
    intrigue: string;
    plotHooks: string[];
    patrons: Patron[];
    history: string;
    lore: string;
    menu?: MenuItem[];
}

/*
@Data
public class LootGenerationResultDTO {
    private UUID id;
    private String inputData;
    private LootGenerationResponseDTO outputData;
    private LocalDateTime createdAt;
    private LocalDateTime updatedAt;
}
*/

export interface ILootGenerationResponse {
    response?: LootGenerationResultDTO;
    error?: string;
}

export interface LootGenerationResultDTO {
    id: string;
    inputData: string;
    outputData: LootGenerationResponseDTO;
    createdAt: string;
    updatedAt: string;
    user: IUserDTO;
    additionalImages?: ImageReferenceDTO[];
}

export interface LootGenerationResponseDTO {
    lootName: string;
    narrative: string;
    lootItems: LootItem[];
}

export interface LootItem {
    itemName: string;
    itemType: string;
    description: string;
    marketValue: string;
}

export interface EditLootGenerationRequest {
    lootName: string;
    narrative: string;
    lootItems: LootItem[];
}

export interface MonsterFight {
    monsterName: string;
    count: number;
}

export interface LootGenerationRequest {
    partyLevel: number;
    theme: string;
    monstersFought: MonsterFight[];
    gameType: string;
}

// Re-export the types from prompt-generator.ts
export type {
    CameraDirection,
    CameraStyle,
    Model,
    Pacing,
    PromptGeneratorInput,
    PromptGeneratorOutput,
    PromptLength,
    SpecialEffects,
    Style,
} from "./prompt-generator";

export interface INPCGenerationResponse {
    response?: NPCGenerationResultDTO;
    error?: string;
}

export interface NPCGenerationResultDTO {
    id: string;
    inputData: string;
    outputData: NPCGenerationResponseDTO;
    createdAt: string;
    updatedAt: string;
    user: IUserDTO;
    additionalImages?: ImageReferenceDTO[];
}

export interface NPCGenerationResponseDTO {
    id: string;
    name: string;
    level: number;
    race: string;
    npcClass: string;
    occupation: string;
    gender: string;
    age: string;
    appearance: string;
    skinColor: string;
    hairColor: string;
    eyeColor: string;
    height: string;
    build: string;
    clothing: string;
    distinguishingFeatures: string[];
    personality: string;
    background: string;
    alignment: string;
    quirks: string[];
    motivation: string;
    speech: string;
    stats: Record<string, number>;
    abilities: string[];
    possessions: string[];
    connections: string[];
    roleInStory: string;
    factionAffiliations: string;
    personalityTraits: string[];
    goal: string;
    secret: string;
    flaws: string[];
    fears: string[];
    wantsNeeds: string[];
    services: string[];
    rewards: string[];
    dialoguePatterns: string[];
    bodyLanguage: string;
    voice: string;
    behaviorPatterns: string[];
    majorSecret: string;
    minorSecrets: string;
    interactionStrategy: string;
    combatStrategy: string;
}

export interface EditNPCGenerationRequest {
    id: string;
    name: string;
    level: number;
    race: string;
    npcClass: string;
    occupation: string;
    gender: string;
    age: string;
    appearance: string;
    skinColor: string;
    hairColor: string;
    eyeColor: string;
    height: string;
    build: string;
    clothing: string;
    distinguishingFeatures: string[];
    personality: string;
    background: string;
    alignment: string;
    quirks: string[];
    motivation: string;
    speech: string;
    stats: Record<string, number>;
    abilities: string[];
    possessions: string[];
    connections: string[];
    roleInStory: string;
    factionAffiliations: string;
    personalityTraits: string[];
    goal: string;
    secret: string;
    flaws: string[];
    fears: string[];
    wantsNeeds: string[];
    services: string[];
    rewards: string[];
    dialoguePatterns: string[];
    bodyLanguage: string;
    voice: string;
    behaviorPatterns: string[];
    majorSecret: string;
    minorSecrets: string;
    interactionStrategy: string;
    combatStrategy: string;
}

export interface NPCGenerationRequest {
    quickPrompt: string;
    race?: string;
    npcClass?: string;
    occupation?: string;
    gender?: string;
    age?: string;
    connections?: string;
    level?: number;
    gameType?: string;
}

export interface ImageReferenceDTO {
    imageId: string;
    url: string;
    webpUrl?: string;
    title?: string;
    associationType?: string;
}
