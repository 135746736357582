"use client";

import React, { FC, useContext, useState } from "react";
import { BookOpenIcon, CodesandboxIcon, DollarSignIcon, GiftIcon, HomeIcon, ImageIcon, Menu, PencilIcon, ShoppingBagIcon, TrophyIcon } from "lucide-react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import GenerateHeaderButton from "@/components/atoms/GenerateHeaderButton";
// Import shadcn components
import { Button } from "@/components/ui/button";
import { GemCounter } from "@/components/ui/gem-counter";
import { GemCounterHeader, SubscribeButton } from "@/components/ui/gem-counter-header";
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from "@/components/ui/navigation-menu";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
// Import custom components
import { AuthButton, MyGalleryButton, ProfileButton, SignOutButton } from "components/molecules/AuthButton";
import { AppContext } from "lib/contexts";
import { UserPremiumType } from "types";
import Icon from "./icon.png";

// Lazy load non-critical components
const Notifications = dynamic(() => import("components/molecules/Notifications"), {
  ssr: false,
  loading: () => <Skeleton className="h-8 w-8 rounded-full" />
});
const GenerateSidebarButton = dynamic(() => import("@/components/atoms/GenerateSidebarButton"), {
  ssr: false,
  loading: () => <Skeleton className="h-8 w-8 rounded-full" />
});

// Lazy load ModernDropdown
const ModernDropdown = dynamic(() => import("@/components/atoms/ModernDropdown").then(mod => mod.ModernDropdown), {
  ssr: false,
  loading: () => <Skeleton className="h-10 w-10 rounded-full" />
});

// Component definitions
const ListItem = React.forwardRef<React.ElementRef<"a">, React.ComponentPropsWithoutRef<"a">>(({
  className,
  title,
  children,
  ...props
}, ref) => {
  return <li>
            <NavigationMenuLink asChild>
                <a ref={ref} className={cn("block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-slate-700 hover:text-sky-400 focus:bg-slate-700 focus:text-sky-400", className)} {...props}>
                    <div className="text-sm font-medium leading-none text-white">
                        {title}
                    </div>
                    <p className="line-clamp-2 text-sm leading-snug text-slate-300">
                        {children}
                    </p>
                </a>
            </NavigationMenuLink>
        </li>;
});
ListItem.displayName = "ListItem";

// Create a NavLink component for mobile navigation
const MobileNavLink: FC<{
  href: string;
  icon: React.ReactNode;
  label: string;
  badge?: React.ReactNode;
  onClose?: () => void;
}> = ({
  href,
  icon,
  label,
  badge,
  onClose
}) => {
  return <Link href={href} onClick={onClose} className="flex w-full items-center gap-3 rounded-md p-3 text-white transition-colors hover:bg-slate-700 hover:text-sky-400" data-sentry-element="Link" data-sentry-component="MobileNavLink" data-sentry-source-file="index.tsx">
            <span className="text-slate-200">{icon}</span>
            <span className="text-base font-medium">{label}</span>
            {badge}
        </Link>;
};

// Create a mobile nav section component
const MobileNavSection: FC<{
  title: string;
  children: React.ReactNode;
}> = ({
  title,
  children
}) => {
  return <div className="space-y-2" data-sentry-component="MobileNavSection" data-sentry-source-file="index.tsx">
            <h3 className="!px-1 md:!px-2 text-xs font-semibold uppercase tracking-wider text-slate-400">
                {title}
            </h3>
            <div className="space-y-1">{children}</div>
            <Separator className="my-2 !bg-slate-700" data-sentry-element="Separator" data-sentry-source-file="index.tsx" />
        </div>;
};
type ModernHeaderProps = {
  isLoggedIn: boolean;
  fetched?: boolean;
  userPremiumLevel?: UserPremiumType;
  userEmail?: string;
};
export const Header: FC<ModernHeaderProps> = ({
  isLoggedIn,
  userPremiumLevel,
  userEmail
}) => {
  const {
    state
  } = useContext(AppContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDesktopMenu, setOpenDesktopMenu] = useState<string | null>(null);
  const handleCloseMobileNav = () => setIsMobileMenuOpen(false);
  const NewBadge = () => <span className="ml-1 inline-flex h-[18px] items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-purple-600 px-1.5 py-0.5 text-[10px] font-medium text-white" data-sentry-component="NewBadge" data-sentry-source-file="index.tsx">
            New
        </span>;
  return <header className="sticky top-0 !z-[50] w-full border-b border-slate-700 bg-[#0f1729] shadow-md px-2" data-sentry-component="Header" data-sentry-source-file="index.tsx">
            <div className="mx-auto flex h-16 w-full max-w-screen-2xl items-center justify-between !px-1 md:!px-2 sm:px-4 lg:px-6">
                {/* Logo and Brand */}
                <div className="flex shrink-0 items-center gap-2">
                    <Link href="/" className="flex items-center gap-1" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Image height={30} width={30} src={Icon} alt="CharGen Icon" aria-label="CharGen Icon" priority={true} className="h-8 w-8 sm:h-9 sm:w-9 md:h-10 md:w-10" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <p className="block sm:hidden md:block lg:hidden xl:block truncate text-lg font-medium text-white sm:text-xl">
                            CharGen
                            {userPremiumLevel !== UserPremiumType.NONE && <span className="align-top text-[8px] text-sky-400">
                                    {userPremiumLevel}
                                </span>}
                        </p>
                    </Link>
                </div>

                {/* Desktop Navigation Menu */}
                <div className="hidden lg:block">
                    <NavigationMenu value={openDesktopMenu ?? ""} onValueChange={setOpenDesktopMenu} className="max-w-none" delayDuration={100} data-sentry-element="NavigationMenu" data-sentry-source-file="index.tsx">
                        <NavigationMenuList className="flex items-center space-x-2" data-sentry-element="NavigationMenuList" data-sentry-source-file="index.tsx">
                            <NavigationMenuItem data-sentry-element="NavigationMenuItem" data-sentry-source-file="index.tsx">
                                <Link href="/pricing" legacyBehavior passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                    <NavigationMenuLink className={cn("flex !h-9 !items-center !rounded-md !px-3 !text-sm !font-medium transition-colors hover:!bg-slate-700 hover:!text-sky-400 focus:!bg-slate-700 focus:!text-sky-400 focus:!outline-none disabled:!pointer-events-none disabled:!opacity-50 data-[active]:!bg-slate-700/50 data-[state=open]:!bg-slate-700/50 bg-transparent text-white")} data-sentry-element="NavigationMenuLink" data-sentry-source-file="index.tsx">
                                        Pricing
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem>

                            <NavigationMenuItem value="tools" data-sentry-element="NavigationMenuItem" data-sentry-source-file="index.tsx">
                                <NavigationMenuTrigger className="h-9 rounded-md bg-transparent px-3 hover:!bg-slate-700 hover:!text-sky-400 focus:!bg-slate-700 focus:!text-sky-400 data-[state=open]:bg-slate-700 data-[state=open]:text-sky-400 text-white text-sm" data-sentry-element="NavigationMenuTrigger" data-sentry-source-file="index.tsx">
                                    <span className="flex items-center">
                                        Tools
                                    </span>
                                </NavigationMenuTrigger>
                                <NavigationMenuContent className="!bg-slate-900 !border !border-slate-700" data-sentry-element="NavigationMenuContent" data-sentry-source-file="index.tsx">
                                    {openDesktopMenu === "tools" && <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                                            <ListItem href="/loot-generator" title="Loot Generator" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                <div className="flex items-center gap-2">
                                                    <GiftIcon className="h-4 w-4" />
                                                    Generate customized loot for
                                                    your adventures
                                                </div>
                                            </ListItem>
                                            <ListItem href="/npc-generator" title="NPC Generator" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                <div className="flex items-center gap-2">
                                                    <BookOpenIcon className="h-4 w-4" />
                                                    Create detailed NPCs with
                                                    unique backstories
                                                    <NewBadge />
                                                </div>
                                            </ListItem>
                                            <ListItem href="/shop-generator" title="Shop Generator" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                <div className="flex items-center gap-2">
                                                    <ShoppingBagIcon className="h-4 w-4" />
                                                    Create unique shops with
                                                    inventories and shopkeepers
                                                </div>
                                            </ListItem>
                                            <ListItem href="/tavern-generator" title="Tavern Generator" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                <div className="flex items-center gap-2">
                                                    <HomeIcon className="h-4 w-4" />
                                                    Generate immersive taverns
                                                    with atmosphere and patrons
                                                </div>
                                            </ListItem>
                                            <ListItem href="/session-companion" title="Session Companion" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                <div className="flex items-center gap-2">
                                                    <BookOpenIcon className="h-4 w-4" />
                                                    Your AI-powered assistant
                                                    for running game sessions
                                                </div>
                                            </ListItem>
                                        </ul>}
                                </NavigationMenuContent>
                            </NavigationMenuItem>

                            <NavigationMenuItem value="gallery" data-sentry-element="NavigationMenuItem" data-sentry-source-file="index.tsx">
                                <NavigationMenuTrigger className="h-9 rounded-md bg-transparent px-3 hover:!bg-slate-700 hover:!text-sky-400 focus:!bg-slate-700 focus:!text-sky-400 data-[state=open]:!bg-slate-700 data-[state=open]:!text-sky-400 text-white text-sm" data-sentry-element="NavigationMenuTrigger" data-sentry-source-file="index.tsx">
                                    <span className="flex items-center">
                                        Gallery
                                    </span>
                                </NavigationMenuTrigger>
                                <NavigationMenuContent className="!bg-slate-900 !border !border-slate-700" data-sentry-element="NavigationMenuContent" data-sentry-source-file="index.tsx">
                                    {openDesktopMenu === "gallery" && <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                                            <ListItem href="/my-gallery" title="My Gallery" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                Your personal collection of
                                                generated content
                                            </ListItem>
                                            <ListItem href="/gallery" title="Public Gallery" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                Browse community-generated
                                                content
                                            </ListItem>
                                        </ul>}
                                </NavigationMenuContent>
                            </NavigationMenuItem>

                            <NavigationMenuItem value="competitions" data-sentry-element="NavigationMenuItem" data-sentry-source-file="index.tsx">
                                <NavigationMenuTrigger className="h-9 rounded-md bg-transparent px-3 hover:!bg-slate-700 hover:!text-sky-400 focus:!bg-slate-700 focus:!text-sky-400 data-[state=open]:!bg-slate-700 data-[state=open]:!text-sky-400 text-white text-sm" data-sentry-element="NavigationMenuTrigger" data-sentry-source-file="index.tsx">
                                    <span className="flex items-center">
                                        Competitions
                                    </span>
                                </NavigationMenuTrigger>
                                <NavigationMenuContent className="!bg-slate-900 !border !border-slate-700" data-sentry-element="NavigationMenuContent" data-sentry-source-file="index.tsx">
                                    {openDesktopMenu === "competitions" && <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                                            <ListItem href="/leaderboards" title="Leaderboards" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                See the top creators in the
                                                community
                                            </ListItem>
                                            <ListItem href="/competitions" title="Competitions" className="hover:!bg-slate-700 hover:!text-sky-400">
                                                Participate in creative
                                                challenges
                                            </ListItem>
                                        </ul>}
                                </NavigationMenuContent>
                            </NavigationMenuItem>

                            <NavigationMenuItem data-sentry-element="NavigationMenuItem" data-sentry-source-file="index.tsx">
                                <Link href="/edit" legacyBehavior passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                    <NavigationMenuLink className={cn("flex h-9 items-center rounded-md px-3 text-sm font-medium transition-colors hover:bg-slate-700 hover:text-sky-400 focus:bg-slate-700 focus:text-sky-400 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-slate-700/50 data-[state=open]:bg-slate-700/50 bg-transparent text-white")} data-sentry-element="NavigationMenuLink" data-sentry-source-file="index.tsx">
                                        Editing
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem>

                            <NavigationMenuItem data-sentry-element="NavigationMenuItem" data-sentry-source-file="index.tsx">
                                <Link href="/loras" legacyBehavior passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                    <NavigationMenuLink className={cn("flex h-9 items-center rounded-md px-3 text-sm font-medium transition-colors hover:bg-slate-700 hover:text-sky-400 focus:bg-slate-700 focus:text-sky-400 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-slate-700/50 data-[state=open]:bg-slate-700/50 bg-transparent text-white")} data-sentry-element="NavigationMenuLink" data-sentry-source-file="index.tsx">
                                        Models
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem>
                        </NavigationMenuList>
                    </NavigationMenu>
                </div>

                {/* Action buttons section */}
                <div className="flex shrink-0 items-center gap-2 sm:gap-3 lg:gap-4">
                    {/* Always visible on mobile: Notifications and Menu */}
                    {isLoggedIn && <div className="relative flex items-center">
                            <Notifications />
                        </div>}

                    {/* Desktop-only elements - hidden on mobile, shown on sm and up */}
                    <div className="hidden md:flex md:items-center md:gap-2">
                        <GenerateHeaderButton data-sentry-element="GenerateHeaderButton" data-sentry-source-file="index.tsx" />

                        {isLoggedIn && <>
                                <div className="ml-0.5">
                                    <Separator orientation="vertical" className="!h-8 !bg-slate-700" />
                                </div>
                                <GemCounter gold={state.gold} showGetMore={false} />
                                <ModernDropdown userPremiumLevel={userPremiumLevel} userEmail={userEmail} />
                            </>}

                        {!isLoggedIn && <AuthButton />}
                    </div>

                    {/* Mobile menu trigger */}
                    <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen} data-sentry-element="Sheet" data-sentry-source-file="index.tsx">
                        <SheetTrigger asChild data-sentry-element="SheetTrigger" data-sentry-source-file="index.tsx">
                            <Button variant="ghost" size="icon" className="block lg:hidden text-white hover:!bg-slate-800 hover:!text-sky-400" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                <Menu className="h-6 w-6" data-sentry-element="Menu" data-sentry-source-file="index.tsx" />
                                <span className="sr-only">Toggle menu</span>
                            </Button>
                        </SheetTrigger>
                        <SheetContent side="right" className="!w-[90vw] !max-w-md !p-0 !border-slate-700 !bg-slate-900 text-white !z-[52]" data-sentry-element="SheetContent" data-sentry-source-file="index.tsx">
                            <div className="flex h-16 items-center justify-between border-b border-slate-700 px-4">
                                <Link href="/" className="flex items-center gap-1" onClick={handleCloseMobileNav} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                    <Image height={30} width={30} src={Icon} alt="CharGen Icon" priority={true} className="h-9 w-9" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    <h1 className="truncate text-lg font-medium text-white">
                                        CharGen
                                        {userPremiumLevel !== UserPremiumType.NONE && <span className="align-top text-[8px] text-sky-400">
                                                {userPremiumLevel}
                                            </span>}
                                    </h1>
                                </Link>
                            </div>

                            {isMobileMenuOpen && <div className="flex flex-col gap-1 !px-1 md:!px-2 py-4 overflow-y-auto max-h-[calc(100vh-64px)]">
                                    <div className="flex flex-col gap-4">
                                        <div className="space-y-3 !px-1 md:!px-2">
                                            <GenerateSidebarButton />
                                            {isLoggedIn && <GemCounterHeader orientation="horizontal" />}
                                            <SubscribeButton userPremiumLevel={userPremiumLevel} fullWidth={true} />
                                            {!isLoggedIn && <AuthButton onClick={handleCloseMobileNav} />}
                                        </div>

                                        <Separator className="!my-1 !bg-slate-700" />

                                        <MobileNavLink href="/pricing" icon={<DollarSignIcon className="h-5 w-5" />} label="Pricing" onClose={handleCloseMobileNav} />

                                        <MobileNavSection title="Tools">
                                            <MobileNavLink href="/loot-generator" icon={<GiftIcon className="h-5 w-5" />} label="Loot Generator" onClose={handleCloseMobileNav} />
                                            <MobileNavLink href="/npc-generator" icon={<BookOpenIcon className="h-5 w-5" />} label="NPC Generator" badge={<NewBadge />} onClose={handleCloseMobileNav} />
                                            <MobileNavLink href="/shop-generator" icon={<ShoppingBagIcon className="h-5 w-5" />} label="Shop Generator" onClose={handleCloseMobileNav} />
                                            <MobileNavLink href="/tavern-generator" icon={<HomeIcon className="h-5 w-5" />} label="Tavern Generator" onClose={handleCloseMobileNav} />
                                            <MobileNavLink href="/session-companion" icon={<BookOpenIcon className="h-5 w-5" />} label="Session Companion" onClose={handleCloseMobileNav} />
                                        </MobileNavSection>

                                        <MobileNavLink href="/edit" icon={<PencilIcon className="h-5 w-5" />} label="Editing Suite" onClose={handleCloseMobileNav} />
                                        <MobileNavLink href="/loras" icon={<CodesandboxIcon className="h-5 w-5" />} label="Models" onClose={handleCloseMobileNav} />

                                        <MobileNavSection title="Gallery">
                                            <MobileNavLink href="/my-gallery" icon={<ImageIcon className="h-5 w-5" />} label="My Gallery" onClose={handleCloseMobileNav} />
                                            <MobileNavLink href="/gallery" icon={<ImageIcon className="h-5 w-5" />} label="Public Gallery" onClose={handleCloseMobileNav} />
                                        </MobileNavSection>

                                        <MobileNavSection title="Competitions">
                                            <MobileNavLink href="/leaderboards" icon={<TrophyIcon className="h-5 w-5" />} label="Leaderboards" onClose={handleCloseMobileNav} />
                                            <MobileNavLink href="/competitions" icon={<TrophyIcon className="h-5 w-5" />} label="Competitions" onClose={handleCloseMobileNav} />
                                        </MobileNavSection>

                                        {isLoggedIn && <div className="space-y-2 mt-4">
                                                <Separator className="!bg-slate-700" />
                                                <MobileNavSection title="Account">
                                                    <ProfileButton />
                                                    <MyGalleryButton />
                                                    <SignOutButton />
                                                </MobileNavSection>
                                            </div>}
                                    </div>
                                </div>}
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        </header>;
};